<template>
  <div class="zoined-chart">
    <div class="title" v-if="title && !loading">{{ title }}</div>
    <div class="alert drilldown-alert panel panel-snippet" v-if="showDrilldownAlert">
      <div class="panel-body">
        {{ graphDrillingToMsg }}
      </div>
      <div class="panel-footer">
        <button class="btn btn-default btn-sm" @click="closeAlert(true)">
          {{ graphDrillingToMsgButton }}
        </button>
        <button class="btn btn-primary btn-default btn-sm" @click="closeAlert(false)">
          {{ graphDrillingToMsgButtonClose }}
        </button>
      </div>
    </div>

    <div class="content" :class="{ paginated: paginationEnabled }">
      <spinner v-show="loading"></spinner>
      <p class="chart-error" v-if="error">{{ error }}</p>
      <div v-if="drilldownStack.length > 0" class="flex-row align-items-center drilldown-info">
        <div>
          <button class="drill-up-button" @click="drillUp">Back</button>
        </div>
        <div v-if="filterDescription" class="flex-1 filter-description">
          {{ filterDescription }}
        </div>
      </div>
      <chart
        v-if="mergedOptions && !loading"
        :options="mergedOptions"
        :extra-render-func="customizeFunc"
        :clean-update="cleanUpdate"
        :formatter-config="formatterConfiguration"
        @point-click="clicked"
        @redraw="$emit('redraw', $event)"
        ref="chart"
      ></chart>
    </div>
    <div
      class="pagination-control"
      v-if="(paginationEnabled || limitEnabled) && mergedOptions && !loading && (editing || !isSmallSnippet)"
    >
      <pagination
        v-if="paginationEnabled"
        :page="pagingInfo.page"
        :totalPages="pagingInfo.totalPages"
        @pageChange="switchPage"
      ></pagination>
      <limit-selector
        v-if="limitEnabled"
        :limit="filters.limit"
        :limitOptions="effectiveLimitOptions"
        @update="$emit('updateLimit', $event)"
      ></limit-selector>
    </div>
  </div>
</template>

<script>
import chart from "./zoined-vue-highchart.vue";
import pagination from "../components/pagination.vue";
import spinner from "../components/spinner.vue";
import { repositionDataLabels } from "../lib/highcharts/labels";
import { drawWeatherSymbols, weatherSeriesHeight, weatherSeriesBotttomSpacing } from "../lib/highcharts/weather";
import { convertGroupingToBusinessCalendar } from "../lib/filter-util";
import limitSelector from "../components/limit-selector";
import _, { debounce } from "lodash";
import { makeApiInstance } from "../api/instance";
import i18n from "../i18n";

const hideDrilldownAlertCookieName = "_zoined_adm_msg_drill";

function toInt(i) {
  if ("number" == typeof i) {
    return i;
  } else if ("string" == typeof i) {
    try {
      return parseInt(i);
    } catch (err) {
      return 0;
    }
  } else {
    return 0;
  }
}

export default {
  components: {
    chart,
    pagination,
    spinner,
    limitSelector,
  },
  props: {
    drilldown: {
      type: Boolean,
      default: true,
    },
    forceDrilldown: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Boolean,
      default: null,
    },
    limitOptions: {
      type: Array,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
    highchartOptions: {
      type: Object,
      required: false,
    },
    component: {
      type: Object,
      required: false,
    },
    // Optional chart data given by parent (overrides fetched data)
    chartData: {
      type: Object,
    },
    cleanUpdate: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentFilters: this.filters,
      options: null,
      drilldownStack: [],
      loading: false,
      fetchIndex: 0,
      error: null,
      user_drilldowns: {},
      isCloseAlert: false,
      isSmallScreen: window.innerWidth < 768,
    };
  },
  inject: ["drilldownData"],
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isSmallScreen = window.innerWidth < 768;
    },
    closeAlert(dontShowAgain) {
      this.isCloseAlert = true;
      if (dontShowAgain) {
        this.setHideDrilldownAlertCookie();
      }
    },
    notifyDrilldown: function(filters) {
      this.$emit("drilldown", filters);
    },
    translate: function(grouping) {
      if (grouping) {
        return i18n.t(`filter.config.${grouping}`);
      } else {
        return "";
      }
    },
    translateCategory: function(category) {
      if (category) {
        return i18n.t(`metadata.category.${category}`);
      } else {
        return "";
      }
    },
    drillUp: function() {
      if (this.drilldownStack.length > 0) {
        this.fetchData(_.last(this.drilldownStack)).then((updated) => {
          if (updated) {
            const filters = this.drilldownStack.pop();
            this.notifyDrilldown(filters);
          }
        });
      } else {
        this.notifyDrilldown(null);
      }
    },
    isCustomDrilldowns(grouping) {
      if (this.drilldownData.selected && this.type != "multi_sales") {
        return this.drilldownData.selected.value;
      } else if (this.drilldownData.selected && this.type == "multi_sales") {
        return [grouping[0], this.drilldownData.selected.value];
      } else {
        return grouping;
      }
    },
    clicked: async function(p) {
      if (!this.forceDrilldown) {
        if (this.drilldownData.selected && p.drilldown) {
          p.drilldown.grouping = this.isCustomDrilldowns(p.drilldown.grouping || p.drilldown.grouping_x);
        }

        if (this.drilldownData.selected && p.drilldown && this.type == "multi_sales") {
          p.drilldown.top_grouping = this.drilldownData.selected.value;
          p.drilldown.grouping = this.isCustomDrilldowns(p.drilldown.grouping);
        }

        if (this.drilldownData.selected && !p.drilldown) {
          p["drilldown"] = _.cloneDeep(this.currentFilters);
          p.drilldown[this.currentFilters.grouping || this.currentFilters.grouping_x] = p.id;
          p.drilldown.grouping = this.drilldownData.selected.value;
        }
      }
      if (this.drilldown && p.drilldown) {
        const previousFilters = this.currentFilters;
        await this.fetchData(p.drilldown).then(() => {
          this.drilldownStack.push(previousFilters);
          this.notifyDrilldown(this.currentFilters);
        });
      } else {
        this.$emit("select", p);
      }
    },
    setHideDrilldownAlertCookie: function() {
      var expiration_date = new Date();
      var cookie_string = "";
      expiration_date.setFullYear(expiration_date.getFullYear() + 1);
      cookie_string = hideDrilldownAlertCookieName + "=1; path=/; expires=" + expiration_date.toUTCString();
      document.cookie = cookie_string;
    },
    checkHideDrilldownAlertCookie: function() {
      var vParams = document.cookie.match(new RegExp("(^| )" + hideDrilldownAlertCookieName + "=([^;]+)"));
      if (vParams && vParams[2] == 1) {
        this.isCloseAlert = true;
      }
    },
    fetchDrilldowns: function(grouping) {
      return makeApiInstance()
        .get(`/api/v1/drilldowns/?metric=${this.metric}&grouping=${grouping}`)
        .then((response) => {
          const json = response.data;
          if (json) {
            this.drilldownData.selected = { value: json, label: this.translate(json) };
          } else {
            const drilldown = this.options.series[0].data[0].drilldown;
            if (drilldown) {
              let fallback = _.last(_.flatten([drilldown.grouping || drilldown.grouping_x]));
              this.drilldownData.selected = {
                value: fallback,
                label: this.translate(fallback),
              };
            } else {
              this.drilldownData.selected = null;
            }
          }

          return true;
        })
        .catch((err) => {
          console.log("Fetch failed with", err);
          return false;
        });
    },
    fetchData: function(filters) {
      filters = filters || this.currentFilters || this.filters;

      if (window.zoinedContext.useBusinessCalendar) {
        filters.grouping = convertGroupingToBusinessCalendar(filters.grouping || filters.grouping_x);
      }

      const chart_options = Object.assign({}, this.chartOptions);

      if (this.component?.static) {
        chart_options.static = true;
      }

      const body = {
        filter: filters,
        chart_options,
      };

      if (this.drilldown) {
        body.drilldown = true;
      }

      if (!this.type) {
        this.loading = false;
        this.error = i18n.t("errors.messages.no_data_available");
        return false;
      }

      return this.doFetchData(body);
    },
    doFetchData(body, chartToken = null) {
      this.fetchIndex++;
      this.loading = true;
      const ongoingIndex = this.fetchIndex;

      this.$emit("loading", true);

      return makeApiInstance({}, { chart_token: chartToken })
        .post("/api/v1/charts/" + this.type + "?locale=" + i18n.locale, body)
        .then((response) => {
          // Only process results if this is latest query we have started
          // This discards multiclicks
          if (ongoingIndex == this.fetchIndex) {
            const json = response.data;
            this.loading = false;

            this.error = null;
            this.currentFilters = body.filter;
            this.options = json;
            this.$emit("loading", false);
            if (this.drilldown && !this.forceDrilldown) {
              this.fetchDrilldowns(body.filter.grouping || body.filter.grouping_x);
            }
            return true;
          }
        })
        .catch((err) => {
          console.log("Fetch failed with", err);
          this.error = err.response?.data?.error || err.message;
          this.options = null;
          this.loading = false;
          this.$emit("loading", false);
          this.$emit("data", null);
          return false;
        });
    },
    switchPage: function(page) {
      const f = _.cloneDeep(this.currentFilters);
      f.offset = (page - 1) * (this.options.paging.limit || 10);

      this.fetchData(f).then((success) => {
        if (success) {
          this.currentFilters = f;
        }
      });
    },
    getHighchartsObject: function() {
      return this.$refs.chart.getHighchartsObject();
    },
  },
  computed: {
    span() {
      return this.component?.span || 12;
    },
    filterDescription() {
      return this.options?.extra?.filterDescription;
    },
    showDrilldownAlert() {
      this.checkHideDrilldownAlertCookie();

      return this.drilldownData.userChangedDrilldown && !this.isCloseAlert;
    },

    isSmallSnippet() {
      return this.span <= 4 && !this.isSmallScreen;
    },
    paginationEnabled: function() {
      return this.pagination;
    },
    limitEnabled: function() {
      return this.limit === true || (this.paginationEnabled && this.limit !== false);
    },
    drillingTo: function() {
      return i18n.t("chart.drilling_to");
    },
    chooseDrilldown: function() {
      return i18n.t("chart.choose_drilling");
    },
    clickGraphDrillingTo: function() {
      return i18n.t("chart.clickGraphDrillingTo");
    },
    clickGraphDrillingToTitle: function() {
      return i18n.t("chart.clickGraphDrillingToTitle");
    },
    clickGraphDrillingToSelect: function() {
      return i18n.t("chart.clickGraphDrillingToSelect");
    },
    graphDrillingToMsg: function() {
      return i18n.t("chart.clickGraphDrillingToTitle"); // graphDrillingToMsg, like to keep it same as "clickGraphDrillingToTitle"
    },
    graphDrillingToMsgButtonClose: function() {
      return i18n.t("chart.graphDrillingToMsgButtonClose");
    },
    graphDrillingToMsgButton: function() {
      return i18n.t("chart.graphDrillingToMsgButton");
    },
    save: function() {
      return i18n.t("actions.save");
    },
    hideFilters: function() {
      return i18n.t("filters.actions.hide");
    },
    selectFilters: function() {
      return i18n.t("filters.actions.select");
    },
    apply: function() {
      return i18n.t("actions.apply");
    },
    infoText: function() {
      return i18n.t("filters.actions.info_text");
    },
    pagingInfo: function() {
      if (!this.options || !this.options.paging) {
        return { page: 1, totalPages: 1 };
      } else {
        const paging = this.options.paging;
        const offset = toInt(paging.offset) || 0;
        const limit = toInt(paging.limit) || 10;
        return {
          page: Math.floor(offset / limit) + 1,
          totalPages: Math.ceil(paging.total_items / limit),
          pageSize: limit,
        };
      }
    },
    selectedText: function() {
      if (this.drilldownData.selected) {
        return this.drilldownData.selected.label;
      } else {
        return i18n.t(`chart.no_default_drilldown`);
      }
    },
    mergedOptions: function() {
      let opts = _.cloneDeep(this.options);
      if (!opts) {
        return null;
      }

      const defaultOptions = {
        xAxis: {
          alternateGridColor: "#f9f9f9",
          lineColor: "#585858",
          tickLength: 0,
        },
        yAxis: {
          lineWidth: 1,
          lineColor: "#585858",
        },
      };

      opts = _.merge({}, defaultOptions, opts);
      if (_.isArray(opts.xAxis)) {
        opts.xAxis = opts.xAxis.map((xAxis) => _.merge({}, defaultOptions.xAxis, xAxis));
      }
      if (_.isArray(opts.yAxis)) {
        opts.yAxis = opts.yAxis.map((yAxis) => _.merge({}, defaultOptions.yAxis, yAxis));
      }

      opts.chart.spacingTop = 0;
      if (opts.weather) {
        opts.chart.spacingBottom =
          (opts.chart.spacingBottom || 0) + opts.weather.length * weatherSeriesHeight + weatherSeriesBotttomSpacing;
      }
      // If we don't have any data, show helpful title
      if (!_.some(opts.series, (s) => s.data.length > 0)) {
        opts.title = {
          text: i18n.t("errors.messages.no_data_available"),
          verticalAlign: "middle",
        };
      }
      const highchartOptions = _.get(this.component, "highchartOptions") || this.highchartOptions;
      if (highchartOptions) {
        if (opts.series && highchartOptions.series) {
          opts.series = opts.series.map((serie, index) => {
            const serieOptsByName = highchartOptions.series.find(({ name }) => name && name === serie.name);
            if (serieOptsByName) {
              return {
                ...serie,
                ..._.pick(serieOptsByName, ["visible"]),
              };
            } else {
              const serieOptsByIndex = highchartOptions.series[index];
              if (serieOptsByIndex && !serieOptsByIndex.name) {
                return {
                  ...serie,
                  ..._.pick(serieOptsByIndex, ["visible"]),
                };
              }
              return serie;
            }
          });
        }
      }
      if (this.isSmallSnippet) {
        opts.chart.height = 200;
      }

      if (this.isSmallScreen || this.span <= 6) {
        if (opts.legend) {
          opts.legend.align = "left";
        }
      }

      return opts;
    },
    formatterConfiguration: function() {
      let dataPointPrecision = 0;
      if (this.mergedOptions?.extra?.dataPointPrecision) {
        dataPointPrecision = this.mergedOptions.extra.dataPointPrecision;
      }

      const newLayout = window.zoinedContext.newLayout;

      return {
        dataPointPrecision,
        newLayout,
      };
    },
    customizeFunc: function() {
      const common = (chart) => {
        repositionDataLabels(chart);
        drawWeatherSymbols(chart);
      };
      return (chart, options) => {
        common(chart, options);
        return [];
      };
    },
    effectiveLimitOptions() {
      const chart = _.get(this.options, "chart", {});
      const isBarChart = chart.type == "bar" || chart.inverted;
      const isPieChart = chart.type == "pie";
      const isInsightChart = this.component?.name == "insight";

      if (this.limitOptions) {
        return this.limitOptions;
      } else if (this.component?.limitOptions) {
        return this.component.limitOptions;
      } else if (isInsightChart) {
        return [5, 10, 20];
      } else if (isBarChart) {
        return [5, 10, 20, 50, 100];
      } else if (isPieChart) {
        return [3, 5, 10, 15, 20];
      } else {
        // column
        return [5, 10, 20, 30, 40];
      }
    },
    metric() {
      let keys = Object.keys(this.filters);
      let params = keys
        .filter((x) => {
          if (x == "metrics" || x == "metrics2" || x == "metrics3") {
            return true;
          }
        })
        .map((x) => this.filters[x])
        .filter((x) => (x && _.isArray(x) ? x.length > 0 : true));

      if (params.length === 0 && this.type) {
        params = this.type;
        if (params.includes("top_")) {
          params = params.substr(4);
        }
        if (params.includes("_trend_top")) {
          params = params.replace("_trend_top", "");
        }
        if (params.includes("_trend_bottom")) {
          params = params.replace("_trend_bottom", "");
        }
        if (params == "basket_scatter") {
          params = "sales";
        }
      }

      return params;
    },
  },
  watch: {
    filters: function(filters, old) {
      if (!_.isEqual(filters, old)) {
        this.drilldownData.selected = null;
        this.currentFilters = filters;
        this.fetchData().then((success) => {
          if (success) {
            this.drilldownStack = [];
          }
        });
      }
    },
    chartOptions: function(options, old) {
      if (!_.isEqual(options, old)) {
        this.fetchData();
      }
    },
    pagingInfo: function(info) {
      this.$emit("pageChanged", _.cloneDeep(info, true));
    },
    type: function() {
      this.fetchData(this.filters).then((success) => {
        if (success) {
          this.drilldownStack = [];
        }
      });
    },
    currentFilters: {
      immediate: true,
      handler() {
        this.drilldownData.currentGrouping = this.currentFilters?.grouping || this.currentFilters?.grouping_x;
      },
    },
    metric: {
      immediate: true,
      handler() {
        this.drilldownData.metric = this.metric;
      },
    },
    chartData: {
      immediate: true,
      handler(newData, oldData) {
        if (newData && !_.isEqual(newData, oldData)) {
          this.options = newData;
        }
      },
    },
    mergedOptions: {
      handler(newOptions, oldOptions) {
        if (newOptions && !_.isEqual(newOptions, oldOptions) && !_.isEqual(newOptions, this.options)) {
          this.$emit("data", newOptions);
        }
      },
    },
  },
  created: function() {
    // Debounce fetchData function to avoid duplicate fetches
    this.fetchData = debounce(this.fetchData, 100, { leading: true });

    if (this.component?.request_params) {
      // predefined params and chart_token with params digest for public pages
      this.doFetchData(this.component.request_params, this.component.chart_token);
    } else {
      if (!this.options) {
        this.fetchData(this.filters);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
p.chart-error {
  color: #222;
  text-align: center;
  font-size: 1.5em;
  width: 100%;
}

.zoined-chart {
  min-height: 100px;
  height: 100%;
}

.title {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.content {
  height: 100%;
}

.content.paginated {
  height: calc(100% - 30px);
}

.pagination-control {
  height: 30px;
  text-align: center;
  position: relative;
}

.pagination-control .pagination {
  margin: 0;
}

.alert.panel.panel-snippet {
  background: white;
  position: absolute;
  width: 60%;
  right: 20%;
  margin: 80px auto 0;
  z-index: 89;
  box-shadow: 0 3px 10px -5px rgb(0, 0, 0);

  > .panel-body {
    padding: 10px 0px 10px 0px;
  }

  > .panel-footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 10px;
    }
  }
}

.drilldown-info {
  margin-top: 10px;
}

.drill-up-button {
  background-color: #393939;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;

  &:active {
    background-color: #666666;
  }
}

.filter-description {
  font-size: 10px;
}
</style>
