<template lang="pug">
.newsletter-definition-editor-view
  .mb-md
    a.btn.btn-primary(@click="back()") {{ 'actions.back' | i18n }}
  h3.hide-new-layout {{ title }}

  .newsletter-definition-editor
    spinner(v-if="loading")
    div(v-if="!loading")
      .row
        .col-md-12
          .alert.alert-danger(v-if='error')
            span {{ error }}

      .row
        .col-md-4
          .form-group(:class="{'has-error': $v.model.title.$error}")
            label.control-label(for='title', style="margin-right: 5px;") 
              | {{'newsletter.title' |i18n}} 
            translationModal(:title="'newsletter.title' |i18n", :config="model.translations.title", @update="onUpdateTitleTranslations")
              i.fa.fa-edit
            input.form-control#title(type='text', v-model='model.title')
        .col-md-4
          .form-group
            label(for='newsletter_type') {{'newsletter.definition.type' |i18n}}
            select#newsletter_type(name='newsletter_type', v-model='model.newsletter_type')
              option(value='') {{ 'newsletter.type.custom' | i18n }}
              option(value='alert' :disabled="!alertsEnabled") {{ 'newsletter.type.alert' | i18n }}
        .col-md-4
          .form-group
            label(for='description') {{'newsletter.description' |i18n}}
            textarea.description(rows=2, cols=100, v-model='model.description')

      .row
        .col-md-4
          .form-group(:class="{'has-error': $v.model.frequency.$error}")
            label.control-label(for='frequency') {{'newsletter.definition.frequency' |i18n}}
            select.form-control#frequency(v-model='model.frequency')
              option
              option(:value="item.id" v-for="item in frequencies") {{ item.name }}
        .col-md-4(v-if='model.frequency=="weekly" || model.frequency=="at_most_weekly"')
          .form-group
            label(for='day_of_week') {{'newsletter.definition.day_of_week' |i18n}}
            select#day_of_week(v-model='model.day_of_week')
              option(:value="item.id" v-for="item in weekDays") {{ item.name }}
        .col-md-4(v-if='model.frequency=="monthly" || model.frequency=="at_most_monthly"')
          .form-group
            label(for='day_of_month') {{'newsletter.definition.day_of_month' |i18n}}
            select#day_of_month(v-model='model.day_of_month')
              option(:value="item.id" v-for="item in monthDays") {{ item.name }}
        .col-md-2(v-if='model.frequency=="quarterly"')
          .form-group
            label(for='month_number') {{'filter.config.month' |i18n}}
            select#month_number(v-model='model.month')
              option(:value="item.id" v-for="item in quarterlyMonthNumbers") {{ item.name }}
        .col-md-2(v-if='model.frequency=="quarterly"')
          .form-group
            label(for='day_of_month') {{'newsletter.definition.day_of_month' |i18n}}
            select#day_of_month(v-model='model.day_of_month')
              option(:value="item.id" v-for="item in monthDays") {{ item.name }}
        .col-md-2(v-if='model.frequency=="yearly"')
          .form-group
            label(for='month') {{'filter.config.month' |i18n}}
            select#month(v-model='model.month')      
              option(:value="item.id" v-for="item in months") {{ item.name }}
        .col-md-2(v-if='model.frequency=="yearly"')
          .form-group
            label(for='day_of_month') {{'newsletter.definition.day_of_month' |i18n}}
            select#day_of_month(v-model='model.day_of_month')
              option(:value="item.id" v-for="item in monthDays") {{ item.name }}
        .col-md-4(v-if='model.newsletter_type != "alert"')
          .form-group
            label(for='sending_time') {{'newsletter.definition.sending_time' |i18n}}
            time-select(name='sending_time', v-model='model.sending_time')
        .col-md-4(v-if='model.newsletter_type == "alert" && model.frequency!="at_most_hourly" && model.frequency!="at_most_once"')
          .form-group
            label(for='sending_time') {{'newsletter.definition.alert_time' |i18n}}
            select(name='sending_time', v-model='model.sending_time')
              option(:value="null") {{'newsletter.definition.sending_time_default' |i18n}}
              option(:value="item.id" v-for="item in sendingTimeOptions") {{ item.name }}
        .col-md-4(v-if='!template && model.frequency=="daily"')
          .form-group
            label(for='day_of_month') {{'actions.send' |i18n}}
            select(name='force', v-model='model.force')
              option(:value="false") {{'newsletter.definition.send_only_if_new_data' |i18n}}
              option(:value="true") {{'newsletter.definition.send_always' |i18n}}
      .row(v-if="template")
        .col-md-12
          .form-group(:class="{'has-error': $v.model.translation_key.$error}")
            label.control-label(for='title', style="margin-right: 5px;") {{ 'dashboard_templates.translation_key' | i18n }}
            .subtitle Translation is saved in localizations (localeapp) under "newsletter_templates.titles.[key]"
            input.form-control#title(type='text', v-model='model.translation_key')
            .help-block(v-if="$v.model.translation_key.$error") Key can contain only characters a-z and _

      .row
        .col-md-12(v-if='model.frequency=="at_most_hourly"')
          .form-group
            label(for='sending_times') {{'newsletter.definition.alert_times' |i18n}}
            p {{'newsletter.definition.alert_times_help_text' |i18n}}
            .sending-times-component
              pillList(
                :items="sendingTimeItems", 
                :available-items="hourlySendingTimeOptions",
                :togglable="false",
                :multi-select="true",
                :searchable="false",
                @update="updateSendingTimes",
              )
        .col-md-12(v-if='model.frequency=="daily" || model.frequency=="at_most_daily" || model.frequency == "at_most_hourly"')
          .form-group
            label(for='exclude_weekdays') {{'newsletter.definition.exclude_weekdays' |i18n}}
            p {{'newsletter.definition.exclude_weekdays_help_txt' |i18n}}
            .selectors
              ul.selector.list-inline
                li.pill.btn(:class='{inactive: day.disabled}', v-for="day in weekDays", @click='toggleExcludeWeekday(day)')
                  | {{day.name}}

      .row
        .col-md-4(v-if="availableCurrencies.length>1")
          .form-group
            label(for='currency') {{'newsletter.currency' |i18n}}
            select#currency(name='currency', v-model='model.currency')
              option(value='') {{ 'newsletter.default_currency' | i18n }}
              option(:value="c.currency" v-for="c in availableCurrencies") {{ c.currency }} ({{ c.symbol }})

      .row(v-if="!template && teams && (isAdmin || teams.length > 1)")
        .col-md-4
          .form-group
            label {{ (isAdmin ? 'newsletter.definition.managed_by_team' : 'newsletter.definition.team') |i18n}}
            select(v-model='model.team_id')
              option(v-if="isAdmin" :value='null') -
              option(:value="t.id" v-for="t in teams") {{ t.name }}

      .row(v-if="model.team_id")
        .col-md-4
          .form-group
            label {{'newsletter.definition.subscribe' |i18n}}
            div
              toggle-button(v-model='model.team_subscription' :true-title="'newsletter.definition.all_users_in_team' | i18n" :false-title="'newsletter.definition.selected_users' | i18n")
      .row(v-if="model.team_id && !model.team_subscription && availableUsersItems")
        .col-md-12
          pill-list(:title="'activerecord.models.user.other' | i18n" :items="subscribedUsersItems", :available-items="availableUsersItems" :searchable="availableUsersItems.length > 10" @update="updateSubscribedUsers")
      
      .row
        .col-md-4
          .form-group.checkbox
            label
              input(type='checkbox', v-model='model.auto_subscribe')
              | {{'newsletter.definition.auto_subscribe' | i18n }}
        .col-md-4(v-if="!template")
          .form-group.checkbox
            label
              input(type='checkbox', v-model='model.include_attachments')
              | {{'newsletter.definition.include_attachments' | i18n }}
      .row(v-if="template")
        .col-md-12
          h3 {{ 'attributes.visibility' | i18n }}

        .col-md-4
          .form-group
            div
              label {{ 'activerecord.attributes.dashboard_template.visible_in_zoined' | i18n }}
            toggle-button(v-model="model.visible_in_zoined")

        .col-md-4
          .form-group
            div
              label {{ 'activerecord.attributes.dashboard_template.add_for_new_customers' | i18n }}
            toggle-button(v-model="model.add_for_new_customers")

        .col-md-12
          .form-group
            div
              label {{ 'activerecord.attributes.dashboard_template.partners' | i18n }}
            partner-selector(v-model="model.partners")

      .row
        .col-md-12
          h3 {{ 'newsletter.definition.global.filters_title' | i18n }}
        .col-md-12
          .form-group.checkbox
            defaultParameterSelector(v-model="model.configuration.global" :title="'newsletter.definition.use_default_parameters_label' | i18n")

          .form-group
            div(v-if='availableFilters && availableFilters.length>0')
              div(style="display: inline-block; margin-right: 20px;")
                filtersetSelector(:config='model.configuration.global.filters' @update="updateFiltersConfig")
              showFiltersetNameToggle(:value="chartOptions.show_filterset_name" @input="updateShowFiltersetName" v-if="hasEnabledFilterSets")
              div(style="margin-top: 15px;")
                filterSelector(:config="model.configuration.global.filters.filters[filter.id]", :filter="filter", @update="updateFilter(filter.id, $event)", v-for="filter in availableFilters" :key="filter.id")
      .row
        .col-md-12
          h3 {{'newsletter.definition.sections.title' | i18n }}

      .row
        .col-md-12.newsletter-definition
          draggable.sections(v-model="model.configuration.sections")
            div(v-for="section in model.configuration.sections")
              newsletterSection(:section='section' :newsletter-type="model.newsletter_type" :time-periods="timePeriods"  @remove='removeSection' @duplicate="duplicateSection"  @update="updateSection")

      .row.buttons-row
        .col-md-12.margin-bottom-fix-50
          button.btn.btn-primary(@click='openAddSectionModal()') {{ 'newsletter.definition.actions.add_section' | i18n }}
          .actions.pull-right
            button.btn.btn-default(@click='preview()') {{ 'actions.preview' | i18n }}
            button.btn.btn-primary(@click='onSaveClicked') {{ 'actions.save' | i18n }}

      add-section-modal(
        ref="addSectionModal" 
        :newsletter-type="model.newsletter_type"
        :allowed-section-types="allowedSectionTypes"
        @add="addSection")
      newsletter-preview-modal(ref="newsletterPreviewModal" :definition="model")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import draggable from "vuedraggable";
import translationModal from "../components/translation-modal.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import filterSelector from "../components/filter-selector.vue";
import newsletterSection from "./newsletter-section.vue";
import { Prop, Ref, Watch } from "vue-property-decorator";
import _ from "lodash";
import moment from "moment";
import i18n from "../i18n";
import Actions from "../store/actions";
import spinner from "../components/spinner.vue";
import { combinePeriods } from "./custom_periods_helper";
import { periodDefaults } from "./constants";
import AddSectionModal from "./add-section-modal.vue";
import NewsletterPreviewModal from "./newsletter-preview-modal.vue";
import pillList from "../components/pill-list.vue";
import toggleButton from "../components/toggle-button.vue";
import partnerSelector from "../components/partner-selector.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import DefaultParameterSelector from "../components/default-parameter-selector.vue";
import TeamsApiService from "../api/teams-api-service";
import MenuItem from "@/interfaces/menu-item";
import PillItem from "@/interfaces/pill-item";
import timeSelect from "../components/time-select.vue";

@Component({
  components: {
    draggable,
    translationModal,
    filtersetSelector,
    showFiltersetNameToggle,
    filterSelector,
    newsletterSection,
    spinner,
    pillList,
    toggleButton,
    partnerSelector,
    AddSectionModal,
    NewsletterPreviewModal,
    DefaultParameterSelector,
    timeSelect,
  },
})
export default class NewsletterDefinitionEditorView extends Vue {
  @Ref("addSectionModal")
  addSectionModal: AddSectionModal;

  @Ref("newsletterPreviewModal")
  newsletterPreviewModal: NewsletterPreviewModal;

  @Prop()
  definition: any;

  @Prop()
  error: string;

  @Prop()
  save: Function;

  @Prop({ default: i18n.t("newsletter.definition.edit_form_title") })
  title: string;

  @Prop({ default: false })
  template: boolean;

  @Prop()
  allowedSectionTypes: string[];

  model: any = null;
  dependenciesLoaded = false;
  partnerIds: string[] = null;
  selectedTeam = null;
  teams: any[] = null;

  @Validations()
  validations = {
    model: {
      title: {
        required,
      },
      frequency: {
        required,
      },
      translation_key: {
        valid: (key: string) => !key || !!key.match(/^[a-z_]*$/),
      },
    },
  };

  get loading() {
    return !this.model || !this.dependenciesLoaded;
  }

  get zoinedContext() {
    return window.zoinedContext;
  }

  get isAdmin() {
    return window.zoinedContext.isAdmin;
  }

  get companyId() {
    return this.zoinedContext.companyId;
  }

  get availableCurrencies() {
    return this.zoinedContext.currencies;
  }

  get firstDayOfWeek() {
    return this.zoinedContext.firstDayOfWeek;
  }

  get weekDays() {
    const days = i18n
      .t("date.day_names")
      .map((v, i) => ({
        id: i,
        name: v.charAt(0).toUpperCase() + v.slice(1),
        disabled: this.model.exclude_weekdays?.includes(i),
      }))
      .sort(({ id: a }, { id: b }) => {
        if (a < this.firstDayOfWeek) {
          a += 7;
        }
        if (b < this.firstDayOfWeek) {
          b += 7;
        }
        return a - b;
      });

    return days;
  }

  get alertsEnabled() {
    return !!this.zoinedContext.insights.visible;
  }

  get enabledSets() {
    const sets = _.get(this.model.configuration, "global.filters.sets");

    return sets?.filter(({ enabled }) => enabled) || [];
  }

  get hasEnabledFilterSets() {
    return this.enabledSets.length > 0;
  }

  get monthDays() {
    return _.map(_.range(1, 32), function(v) {
      return {
        id: v,
        name: v,
      };
    });
  }

  get months() {
    return _.map(_.range(1, 13), function(v) {
      return {
        id: v,
        name: i18n.t("date.month_names." + v),
      };
    });
  }

  get quarterlyMonthNumbers() {
    return _.map(_.range(1, 4), function(v) {
      return {
        id: v,
        name: v,
      };
    });
  }
  // List times from 00:00 to 23:45 in 15 minute increments, total 24*4 = 96 options.
  get sendingTimeOptions() {
    return _.map(_.range(0, 96), function(v) {
      const m = moment([2000, 1, 1]).add(15 * v, "minutes");
      const id = m.format("HH:mm");
      return {
        id,
        name: m.format("LT"),
      };
    });
  }

  get hourlySendingTimeOptions() {
    return _.map(_.range(0, 48), function(v) {
      const m = moment([2000, 1, 1, 0, 15, 0]).add(30 * v, "minutes");
      const id = m.format("HH:mm");
      return {
        id,
        key: id,
        name: m.format("LT"),
      };
    });
  }

  get sendingTimeItems() {
    const sendingTimes = this.model ? this.model.sending_times : null;

    return sendingTimes?.map((value) => ({
      value,
      name: this.sendingTimeOptions.find(({ id }) => id === value).name,
    }));
  }

  get newsletterType() {
    return this.model?.newsletter_type;
  }

  get frequency() {
    return this.model?.frequency;
  }

  get frequencies() {
    const frequencies =
      this.newsletterType === "alert"
        ? ["at_most_once", "at_most_hourly", "at_most_daily", "at_most_weekly", "at_most_monthly"]
        : ["daily", "weekly", "monthly", "quarterly", "yearly"];

    return frequencies.map((id) => ({
      id,
      name: i18n.t(`newsletter.frequency.${id}`),
    }));
  }

  get availableFilters() {
    return this.$store.state.parameters.filters.all;
  }

  get timePeriods() {
    return this.$store.state.parameters.timePeriods.all;
  }

  get chartOptions() {
    return this.model.configuration.global?.chart_options || {};
  }

  get customPeriods() {
    return this.$store.state.customPeriods.all;
  }

  get periodOptions() {
    return this.customPeriods && combinePeriods(this.customPeriods);
  }

  get teamId() {
    return this.model?.team_id;
  }

  get subscribedUsersItems(): PillItem[] {
    if (this.model?.team_member_subscriber_ids && this.selectedTeam) {
      return this.model.team_member_subscriber_ids.map((value) => ({
        value,
        name: this.selectedTeam.members.find(({ id }) => id === value)?.name,
      }));
    } else {
      return [];
    }
  }

  get availableUsersItems(): MenuItem[] {
    return this.selectedTeam?.members.map(({ id, name }) => ({ id, name }));
  }

  created() {
    const actions = [];

    if (!this.$store.state.customPeriods) {
      actions.push(this.$store.dispatch(Actions.fetchCustomPeriods));
    }
    if (!this.$store.state.pdfReports.all) {
      actions.push(this.$store.dispatch(Actions.fetchPdfReports));
    }
    if (!this.$store.state.excelReports.all) {
      actions.push(this.$store.dispatch(Actions.fetchExcelReports));
    }
    if (!this.$store.state.filtersets.all) {
      actions.push(this.$store.dispatch(Actions.fetchFiltersets));
    }

    if (this.isAdmin) {
      actions.push(new TeamsApiService().getTeams().then((teams) => (this.teams = teams)));
    } else {
      // team admin
      this.teams = this.zoinedContext.current_role.team_memberships
        .filter(({ role }) => role === "admin")
        .map(({ team_id, name }) => ({ id: team_id, name }));
    }

    Promise.all(actions).then(() => (this.dependenciesLoaded = true));

    this.updateModel();
  }

  back() {
    history.back();
  }

  onUpdateTitleTranslations(translations) {
    Vue.set(this.model.translations, "title", translations);
  }

  trySetPeriodDefault(s, frequency) {
    const defaults = periodDefaults[s.type.id];
    if (
      defaults &&
      defaults[frequency] &&
      (!s.time_period ||
        !_.find(_.get(this.periodOptions, "frequency"), function(o) {
          return o.id === s.time_period;
        }))
    ) {
      Vue.set(s, "time_period", defaults[frequency]);
    }
  }

  toggleExcludeWeekday(day) {
    let weekdays = this.model.exclude_weekdays || [];
    if (weekdays.includes(day.id)) {
      weekdays.splice(weekdays.indexOf(day.id), 1);
    } else {
      weekdays.push(day.id);
    }
    Vue.set(this.model, "exclude_weekdays", weekdays);
  }

  onSaveClicked() {
    this.$v.$touch();

    // Checks that it's valid and sets errors
    if (this.$v.$invalid || !this.model.configuration.valid(this.model)) {
      return;
    }

    this.save(this.model);
  }

  preview() {
    if (!this.model.configuration.valid(this.definition)) {
      return;
    }
    const definition = { ...this.model, configuration: this.model.configuration.toJson() };
    this.newsletterPreviewModal.show(definition);
  }

  removeSection(section) {
    const i = this.model.configuration.sections.indexOf(section);
    if (i !== -1) {
      this.model.configuration.sections.splice(i, 1);
    }
  }

  duplicateSection(section) {
    const i = this.model.configuration.sections.indexOf(section);
    if (i >= 0) {
      const newSection = _.cloneDeep(section);
      this.model.configuration.sections.splice(i + 1, 0, newSection);
    }
  }

  openAddSectionModal() {
    this.addSectionModal.show();
  }

  addSection(type) {
    const section = {
      type,
      ...type.defaults,
    };
    this.trySetPeriodDefault(section, this.frequency);
    const sections = [...this.model.configuration.sections, section];
    Vue.set(this.model.configuration, "sections", sections);
  }

  updateSection(section, config) {
    const sections = this.model.configuration.sections.map((each) => (each === section ? config : each));
    Vue.set(this.model.configuration, "sections", sections);
  }

  updateFilter(filter, filters) {
    this.model.configuration.global.filters.filters = {
      ...this.model.configuration.global.filters.filters,
      [filter]: filters,
    };
  }

  updateSendingTimes({ items }) {
    this.model.sending_times = items.map(({ value }) => value);
  }

  updateShowFiltersetName(show_filterset_name) {
    this.updateChartOptions({
      ...this.chartOptions,
      show_filterset_name,
    });
  }

  updateFiltersConfig(config) {
    Vue.set(this.model.configuration.global, "filters", config);
  }

  updateChartOptions(config) {
    Vue.set(this.model.configuration.global, "chart_options", config);
  }

  updateSubscribedUsers({ items }: { items: PillItem[] }) {
    this.model.team_member_subscriber_ids = items.map(({ value }) => value);
  }

  @Watch("definition")
  updateModel() {
    if (!this.definition) {
      this.model = null;
      return;
    }
    const defaults = {
      frequency: "",
      newsletter_type: "",
      day_of_week: this.firstDayOfWeek,
      day_of_month: 1,
      month_number: 1,
      month: 1,
      sending_time: null,
      sending_times: null,
      team_id: null,
      team_subscription: true,
      team_member_subscriber_ids: [],
      force: false,
    };
    if (this.template) {
      Object.assign(defaults, {
        visible_in_zoined: true,
        add_for_new_customers: false,
        translation_key: null,
      });
    }
    if (!this.isAdmin) {
      Object.assign(defaults, {
        team_id: this.teams[0].id,
      });
    }

    const model = { ...defaults, ..._.cloneDeep(this.definition) };

    if (model.newsletter_type === null) {
      model.newsletter_type = "";
    }

    this.model = model;
  }

  @Watch("newsletterType")
  onNewsletterTypeChanged(value, old) {
    if (old !== undefined) {
      // Clear sections
      this.model.configuration.sections = [];

      if (value == "alert") {
        this.model.frequency = "at_most_weekly";
      } else {
        this.model.frequency = "daily";
      }
    }
  }

  @Watch("frequency")
  onFrequencyChanged(value, old) {
    if (value && old !== undefined && value !== old) {
      if (value === "weekly") {
        this.model.day_of_week = this.firstDayOfWeek;
      }
      if (value === "quarterly") {
        this.model.month_number = 1;
        this.model.day_of_month = 1;
      }
      if (value === "monthly") {
        this.model.day_of_month = 1;
      }
      if (value === "yearly") {
        this.model.day_of_month = 1;
        this.model.month = 1;
      }
      if (value === "at_most_hourly") {
        this.model.sending_time = null;
      } else {
        this.model.sending_times = null;
      }
      _.each(this.model.configuration.sections, (s) => {
        this.trySetPeriodDefault(s, value);
      });
    }
  }

  @Watch("teamId")
  async onTeamChanged(teamId) {
    if (teamId) {
      this.selectedTeam = await new TeamsApiService().getTeam(teamId);
    } else {
      this.selectedTeam = null;
    }
  }
}
</script>
