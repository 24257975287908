import { Base64 } from "js-base64";
import i18n from "../i18n";
import DashboardPage from "../dashboard/dashboard-page.vue";
import CustomDashboardPage from "../analytics/custom-dashboard-page.vue";
import ReportPage from "../analytics/report-page.vue";
import EditReportPage from "../analytics/edit-report-page.vue";
import ReportPdfPage from "../analytics/report-pdf-page.vue";
import ZoinedAdmin from "../zoined-admin/zoined-admin.vue";
import DashboardTemplatesList from "../zoined-admin/dashboard-templates/dashboard-templates-list.vue";
import DashboardTemplateEditor from "../zoined-admin/dashboard-templates/dashboard-template-editor.vue";
import ReportTemplatesList from "../zoined-admin/report-templates/report-templates-list.vue";
import ReportTemplateEditor from "../zoined-admin/report-templates/report-template-editor.vue";
import NewsletterTemplatesList from "../zoined-admin/newsletter-templates/newsletter-templates-list.vue";
import NewsletterTemplateEditor from "../zoined-admin/newsletter-templates/newsletter-template-editor.vue";
import EditCustomDashboardPage from "../custom-dashboard-editor/edit-custom-dashboard-page.vue";
import EditCustomReportPage from "../custom-report/edit-custom-report-page.vue";
import ProfileIndex from "../profile/profile-index.vue";
import PublicReportPage from "../public/public-report-page.vue";
import CompanyAdmin from "../company-admin/company-admin.vue";
import TeamAdmin from "../team-admin/team-admin.vue";
import NewsletterDefinitionsPage from "../newsletter-admin/newsletter-definitions-page.vue";
import NewsletterDefinitionEditPage from "../newsletter-definition-editor/newsletter-definition-edit-page.vue";
import SubscriptionsListPage from "../newsletter-subscriptions/subscriptions-list-page.vue";
import UserListing from "@/user-management/user-listing.vue";
import TeamListing from "@/team-management/team.listing.vue";
import TeamMembersListing from "@/team-management/team-members-listing.vue";
import NewsletterSubscriptions from "@/newsletter-admin/newsletter-subscriptions.vue";
import CompanySettings from "@/company-settings/company-settings.vue";
import AuditLog from "@/audit-log/audit-log.vue";
import BudgetPlanning from "@/budget-planning/budget-planning.vue";
import DataManagement from "@/data-management/data-management.vue";
import EmailDeliveriesList from "@/zoined-admin/email-deliveries/email-deliveries-list.vue";
import UsersList from "@/zoined-admin/users/users-list.vue";

const routes = [
  {
    name: "root",
    path: "/",
    redirect: window.zoinedContext.defaultPath,
  },
  {
    name: "profile",
    path: "/profile",
    component: ProfileIndex,
    meta: {
      title: i18n.t("profile.profile"),
    },
  },
  {
    name: "system_dashboard",
    path: "/dashboard",
    component: DashboardPage,
  },
  {
    name: "new_custom_report",
    path: "/reports/new",
    component: EditCustomReportPage,
    props: (route) => ({
      templateId: route.query.templateId?.toString(),
      duplicateId: route.query.duplicateId?.toString(),
      config: parseBase64EndodedJSON(route.query.config),
    }),
  },
  {
    name: "edit_custom_report",
    path: "/reports/:id/edit",
    component: EditCustomReportPage,
    props: true,
  },
  {
    name: "custom_report",
    path: "/reports/:id",
    component: CustomDashboardPage,
    props: true,
  },
  {
    name: "new_custom_dashboard",
    path: "/dashboards/new",
    component: EditCustomDashboardPage,
    props: (route) => ({
      templateId: route.query.templateId?.toString(),
      duplicateId: route.query.duplicateId?.toString(),
      config: parseBase64EndodedJSON(route.query.config),
    }),
  },
  {
    name: "edit_custom_dashboard",
    path: "/dashboards/:id/edit",
    component: EditCustomDashboardPage,
    props: true,
  },
  {
    name: "custom_dashboard",
    path: "/dashboards/:id",
    component: CustomDashboardPage,
    props: true,
  },
  {
    name: "new_report",
    path: "/analytics/report/:name/new",
    component: EditReportPage,
    props: (route) => ({
      name: route.params.name,
      duplicateId: route.query.duplicateId?.toString(),
      config: parseBase64EndodedJSON(route.query.config),
    }),
  },
  {
    name: "edit_report",
    path: "/analytics/report/:name/:myReportId/edit",
    component: EditReportPage,
    props: true,
  },
  {
    name: "report",
    path: "/analytics/report/:name/:myReportId?",
    component: ReportPage,
    props: true,
  },
  {
    name: "report_pdf",
    path: "/api/v1/reports/show_for_pdfs/:id",
    component: ReportPdfPage,
  },
  {
    name: "subscriptions",
    path: "/newsletter_subscriptions",
    component: SubscriptionsListPage,
  },
  {
    path: "/company_admin",
    component: CompanyAdmin,
    children: [
      {
        path: "",
        redirect: "/company_admin/users",
      },
      {
        name: "company_users",
        path: "users",
        component: UserListing,
        meta: {
          title: i18n.t("company_admin.users"),
        },
      },
      {
        name: "company_teams",
        path: "teams",
        component: TeamListing,
        meta: {
          title: i18n.t("company_admin.teams.title"),
        },
      },
      {
        name: "company_team_members",
        path: "teams/:id/members",
        component: TeamMembersListing,
        props: true,
      },
      {
        name: "company_newsletter_definitions",
        path: "newsletter_definitions",
        component: NewsletterDefinitionsPage,
        meta: {
          title: i18n.t("newsletter.definition.list_title"),
        },
      },
      {
        name: "company_new_newsletter_definition",
        path: "newsletter_definitions/new",
        component: NewsletterDefinitionEditPage,
        props: (route) => ({
          duplicateId: route.query.duplicateId,
          templateId: route.query.templateId,
        }),
        meta: {
          title: i18n.t("newsletter.definition.new_form_title"),
        },
      },
      {
        name: "company_edit_newsletter_definition",
        path: "newsletter_definitions/:id/edit",
        component: NewsletterDefinitionEditPage,
        props: true,
        meta: {
          title: i18n.t("newsletter.definition.edit_form_title"),
        },
      },
      {
        name: "company_newsletter_subscriptions",
        path: "newsletter_subscriptions",
        component: NewsletterSubscriptions,
        meta: {
          title: i18n.t("newsletter.subscription.list_title"),
        },
      },
      {
        name: "company_settings",
        path: "settings",
        component: CompanySettings,
        meta: {
          title: i18n.t("company_admin.configuration.header"),
        },
      },
      {
        name: "company_audit_log",
        path: "audit_log",
        component: AuditLog,
        meta: {
          title: i18n.t("company_admin.audit_log.title"),
        },
      },
      {
        name: "company_budget",
        path: "budget",
        component: BudgetPlanning,
        meta: {
          title: i18n.t("company_admin.tabs.planning"),
        },
      },
      {
        name: "company_data_management",
        path: "data_management",
        component: DataManagement,
        meta: {
          title: i18n.t("company_admin.data_management.title"),
        },
      },
    ],
  },
  {
    path: "/team_admin",
    component: TeamAdmin,
    children: [
      {
        path: "",
        redirect: "/team_admin/newsletter_definitions",
      },
      {
        name: "team_newsletter_definitions",
        path: "newsletter_definitions",
        component: NewsletterDefinitionsPage,
        meta: {
          title: i18n.t("newsletter.definition.list_title"),
        },
      },
      {
        name: "team_new_newsletter_definition",
        path: "newsletter_definitions/new",
        component: NewsletterDefinitionEditPage,
        props: (route) => ({
          duplicateId: route.query.duplicateId,
          templateId: route.query.templateId,
        }),
        meta: {
          title: i18n.t("newsletter.definition.new_form_title"),
        },
      },
      {
        name: "team_edit_newsletter_definition",
        path: "newsletter_definitions/:id/edit",
        component: NewsletterDefinitionEditPage,
        props: true,
        meta: {
          title: i18n.t("newsletter.definition.edit_form_title"),
        },
      },
    ],
  },
  {
    path: "/zoined_admin",
    component: ZoinedAdmin,
    children: [
      {
        path: "",
        redirect: "/zoined_admin/report_templates",
      },
      {
        name: "users",
        path: "users",
        component: UsersList,
        meta: {
          title: i18n.t("users.title"),
        },
      },
      {
        name: "dashboard_templates",
        path: "dashboard_templates",
        component: DashboardTemplatesList,
        meta: {
          title: i18n.t("dashboard_templates.title"),
        },
      },
      {
        name: "new_dashboard_template",
        path: "dashboard_templates/new",
        component: DashboardTemplateEditor,
        props: (route) => ({
          dashboardId: route.query.dashboardId?.toString(),
          templateId: route.query.templateId?.toString(),
        }),
        meta: {
          title: i18n.t("dashboard_templates.new_dashboard_template"),
        },
      },
      {
        name: "edit_dashboard_template",
        path: "dashboard_templates/:id/edit",
        component: DashboardTemplateEditor,
        props: true,
        meta: {
          title: i18n.t("dashboard_templates.edit_dashboard_template"),
        },
      },
      {
        name: "report_templates",
        path: "report_templates",
        component: ReportTemplatesList,
        meta: {
          title: i18n.t("report_templates.title"),
        },
      },
      {
        name: "new_report_template",
        path: "report_templates/new",
        component: ReportTemplateEditor,
        props: (route) => ({
          reportId: route.query.reportId?.toString(),
          templateId: route.query.templateId?.toString(),
        }),
        meta: {
          title: i18n.t("report_templates.new_report_template"),
        },
      },
      {
        name: "edit_report_template",
        path: "report_templates/:id/edit",
        component: ReportTemplateEditor,
        props: true,
        meta: {
          title: i18n.t("report_templates.edit_report_template"),
        },
      },
      {
        name: "newsletter_templates",
        path: "newsletter_templates",
        component: NewsletterTemplatesList,
        meta: {
          title: i18n.t("newsletter_templates.title"),
        },
      },
      {
        name: "new_newsletter_template",
        path: "newsletter_templates/new",
        component: NewsletterTemplateEditor,
        props: (route) => ({
          templateId: route.query.templateId?.toString(),
          definitionId: route.query.definitionId?.toString(),
        }),
        meta: {
          title: i18n.t("newsletter_templates.new_newsletter_template"),
        },
      },
      {
        name: "edit_newsletter_template",
        path: "newsletter_templates/:id/edit",
        component: NewsletterTemplateEditor,
        props: true,
        meta: {
          title: i18n.t("newsletter_templates.edit_newsletter_template"),
        },
      },
      {
        name: "email_deliveries",
        path: "email_deliveries",
        component: EmailDeliveriesList,
        meta: {
          title: i18n.t("email_deliveries.title"),
        },
      },
    ],
  },
  {
    name: "public_report",
    path: "/public/reports/:token/:filter_token?",
    component: PublicReportPage,
    props: true,
  },
];

const parseBase64EndodedJSON = (str: string) => {
  try {
    return str && JSON.parse(Base64.decode(str));
  } catch {
    return null;
  }
};

export default routes;
